<template>
	<div style="padding:0 20px">
		<el-form status-icon label-width="0px" label-position="left" style="margin-top:20px">
			<el-form-item>
				<el-input v-model="check_code" placeholder="请输入验证码" :disabled="is_disbaled" auto-complete="new-password" clearable>
					<el-button style="width:100px" slot="prepend">验证码</el-button>
					<el-button @click="get_verify" type="success" slot="append" style="width:112px">{{verifyTips}}</el-button>
				</el-input>
			</el-form-item>
			<el-form-item>
				<el-input v-model="pwd1" placeholder="请输入新的登录密码" auto-complete="new-password" type="password" clearable>
					<el-button style="width:100px" slot="prepend">新密码</el-button>
				</el-input>
			</el-form-item>
			<el-form-item>
				<el-input v-model="pwd2" placeholder="再次输入登录密码" auto-complete="new-password" type="password" clearable>
					<el-button style="width:100px" slot="prepend">再次输入</el-button>
				</el-input>
			</el-form-item>
		</el-form>
		<div style="margin-top:15px">
			<el-button @click="sub" style="width: 100%;" type="primary">提交</el-button>
		</div>
	</div>
</template>

<script>
	export default {
		props:{
			type:Number,//1:明细,2:登陆密码修改界面,3:充值
		},
		created(){
			// setTime
			// console.log("creat");
			let that=this;
			setTimeout(function(){
				that.is_disbaled=false;
			},1000)
		},
		data() {
			return {
				
				is_disbaled:true,
				
				//读秒
				verifyTips: '获取验证码',

				//获取验证码锁定
				check_code_lock:false,

				//秒数
				sec:60,

				//验证码
				check_code:'',

				//新密码
				pwd1:'',
				pwd2:'',
			}
		},
		methods: {

			//提交修改登陆密码
			sub(){

				if(!this.$my.check.is_login_pwd(this.pwd1)){
					this.$my.other.msg({
						type:'warning',
						str:'密码需包含字母，符号或者数字中至少两项且长度超过6位数，最多不超过16位数',
					})
					return;
				}
				if(!this.pwd2){
					this.$my.other.msg({
						type:'warning',
						str:'请再次输入登陆密码',
					})
					return;
				}
				if(this.pwd1!==this.pwd2){
					this.$my.other.msg({
						type:'warning',
						str:'两次输入不一致',
					})
					return;
				}

				if(!this.check_code){
					this.$my.other.msg({
						type:'warning',
						str:'请输入正确的验证码',
					})
					return;
				}
				if(this.check_code.length!=4){
					this.$my.other.msg({
						type:'warning',
						str:'请输入正确的验证码',
					})
					return;
				}
				
				this.$my.net.req({
					data:{
						mod:'app_user',
						ctr:'reset_loginpwd_by_app_user',
						ide:this.check_code,
						login_pwd:this.pwd1
					},
					callback:(res)=>{
						
						this.$my.other.msg({
							type:'success',
							str:'重置成功',
						})

						this.check_code='';
						this.pwd1='';
						this.pwd2='';

						//通知
						this.$emit('done')
					}
				});
			},

			//获取验证码
			get_verify(){

				//是否锁定
				if(this.check_code_lock){
					this.$my.other.msg({
						type:'warning',
						str:'60秒内不能重复获取验证码',
					})
					return;
				}

				//发送中...
				this.verifyTips="发送中...";
				
				//发送请求
				this.$my.net.req({
					data:{
						mod:'app_user',
						ctr:'send_ide_for_reset_anypwd'
					},
					callback:(data)=>{
						
						//锁定发送按钮
						this.check_code_lock=true;
						
						//开始倒计时
						this.interval_id=setInterval(()=>{
							this.verifyTips=this.sec+"S";
							this.sec--;
							if(this.sec==0){
								this.check_code_lock=false;
								this.sec=60;
								this.verifyTips="重新发送";
								clearInterval(this.interval_id)
							}
						},1000);
					}
				});
			},
		}
	}
</script>